<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Reset Password v1 -->
      <b-card class="mb-0">
        <!-- logo -->
        <Header />
        <b-card-title class="mb-1 ">
          Reset Password 🔒
        </b-card-title>
        <b-card-text class="mb-2">

          <b-alert
            v-if="verificationData.fullName"
            show
            variant="success"
            class="p-1"
          >
            Dear <p v-if="verificationData.fullName"><b> {{ verificationData.fullName }} </b> please Change your Password,
              If your not  <b> {{ verificationData.fullName }} </b> <b-link
                :disabled="isLoading"
                @click="onCancel"
              > Click here
              </b-link>
            </p>
            <ul>
              <li>Enter a password min 8 characters (must contain- 1 Uppercase, 1 Lowercase, 1 numeric, 1 symbol)</li>
              <li>Your new password must be different from previously used passwords</li>
            </ul>
          </b-alert>
        </b-card-text>

        <!-- form -->
        <validation-observer ref="passwordReset">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="passwordResetForm"
          >

            <!-- password -->
            <b-form-group
              label="New Password"
              label-for="reset-password-new"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-new"
                    v-model="password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="reset-password-new"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="reset-password-confirm"
              label="Confirm Password"
            >
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="reset-password-confirm"
                    v-model="cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="reset-password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              block
              type="submit"
              variant="primary"
              :disabled="isLoading"
            >
              Set New Password
            </b-button>
          </b-form>
        </validation-observer>

        <!-- /Forgot password-->

        <!-- /footer-->
        <b-col
          lg="12"
          class="mt-2"
        >
          <Footer />
        </b-col>
      </b-card>
    <!-- /Reset Password v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCol,
  // BImg,
  BCard, BAlert, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AuthService from '@/@service/api/Auth.service'
import LocalStorageService from '@/@service/utils/LocalStorage.service'

import Header from '@/@core/components/landding-page/Header.vue'
import Footer from '@/@core/components/landding-page/Footer.vue'
import FemsToastrService from '@/@service/utils/FemsToastr.service'

export default {
  components: {
    // VuexyLogo,
    BCol,

    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    // BImg,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    Header,
    Footer,
  },
  data() {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      userName: LocalStorageService.get('user_username'),
      getPageData: JSON.parse(LocalStorageService.get('pageData')) || '',
      pageData: {},
      isLoading: false,
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
      verificationData: JSON.parse(LocalStorageService.get('verificationData')),

    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    if (!this.userName) {
      this.$router.push({ name: 'auth-login' })
    }
    if (this.getPageData) {
      this.isLoaddingPreloader = false
      this.pageData = this.getPageData
    } else {
      this.$router.push({ name: 'auth-login' })
    }
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    passwordResetForm() {
      this.$refs.passwordReset.validate().then(success => {
        if (success && this.userName) {
          this.isLoading = true
          AuthService.newUserPasswordReset({
            user_username: this.userName,
            password: this.password,
            password_confirmation: this.cPassword,
          })
            .then(resp => {
              const response = resp.data
              this.isLoading = false

              const responseData = response
              if (!responseData.status && responseData?.validation_error) {
                const validation = responseData?.validation_error

                if (validation.user_username) {
                  this.$refs.passwordReset.setErrors({
                    Password: [validation.user_username ? validation.user_username[0] : null],
                  })
                }

                if (validation.password) {
                  this.$refs.passwordReset.setErrors({
                    Password: [validation.password ? validation.password[0] : null],
                  })
                }

                FemsToastrService.error(responseData?.message)

                this.isLoading = false
                return
              }

              this.isLoading = false
              if (!responseData.status) {
                FemsToastrService.error(responseData.message || 'Something went wrong')
                return
              }
              if (responseData.status) {
                LocalStorageService.clear()
                LocalStorageService.set('isNew', 1)
                this.$router.replace({ name: 'auth-login' })
                FemsToastrService.success(response.message || 'Your Password update')
              }
            }).catch((error => {
              this.isLoading = false
              let errorMessage = 'Something went wrong'
              if (error?.errors?.password) {
                errorMessage = error?.errors?.password[0]
              }

              FemsToastrService.error(errorMessage || 'Something went wrong')
            }))
        }
      })
    },
    onCancel() {
      LocalStorageService.clear()
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
